import '../../styles/pages/spring-summer-2023/spring-summer-2023-index.scss'
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import Link from "gatsby-plugin-transition-link/AniLink";
import {springSummer2023Stories} from '../../components/spring-summer-2023-stories/stories';
import ReactFullpage from '@fullpage/react-fullpage';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
import Icon from "../../components/icons"
import Footer from "../../components/footer";

// import ITAT from '../images/spring-2020/campaign-logo-horiz-with-tag-white.svg'
import CSUFNews from '../../images/fall-winter-2020/csuf-news.svg'
import ClassNotesHero from '../../images/spring-summer-2023/class-notes-hero.jpg';
import socialBanner from "../../images/spring-summer-2023/social-banners/cover-spring-summer-2023.jpg";

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../../scripts/fullpage.scrollHorizontally.min.js');
};


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
  }

  componentWillUnmount() {
    // console.log('unmount')
    // window.fullpage_api.destroy('all');
  }

  render() {
    var pageTitle = 'Titan - The Digital Magazine of Cal State Fullerton';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton.. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.';
    var slug = 'index-spring-summer-2023';


    const list = springSummer2023Stories.map(function(story){
      if(story.slug != 'presidents-message') {
        var url = "/spring-summer-2023/" + story.slug + "/";

        var linkStyle = {
          backgroundImage: `url(${story.thumbnailIndex})`
        }

        var subtitle = null;
        if (story.subtitle && story.subtitle != '') {
          subtitle = <span className="subtitle">{story.subtitle}</span>
        }

        // var classname = null;
        // if (story.slug === 'under-the-big-top') {
        //   var classname = 'animated fadeInUp delay-2s';
        // }

        var link;
        if (story.external === 'TRUE') {
          var link = <a
          className="story-button"
          target='_blank'
          href={process.env.SITE_URL + url}>
            Read More <Icon name="arrow-right" alt="Right Arrow" class="xs" />
          </a>
        } else {
          var link = <Link
          paintDrip
          className="story-button"
          hex="#FF7900"
          duration={.75}
          to={url}>
            Read More <Icon name="arrow-right" alt="Right Arrow" class="xs" />
          </Link>
        }

        return (
          <section className="section" id={story.slug} key={story.slug} style={linkStyle}>
            <div className={`text ${story.homepageTextPosition}`}>
              <h2>{story.title}</h2>
              {subtitle}
              {link}
            </div>
            <div className={`tint ${story.homepageTextPosition}`} />
          </section>
        )
      }
    })

    return (
      <Layout locationInfo={this.props.location} eaderHide={this.state.headerHide} issue="spring-summer-2023">
        <Helmet>
          <body className="spring-summer-2023-index" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <Preloader fadeDuration={1000} style={{backgroundColor: '#111'}}>
          <h1 style={{display: 'none'}}>Titan: Fall/Winter 2021</h1>

          <ReactFullpage
          id="index-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav, .text-container'}
          loopBottom={true}
          loopTop={true}
          navigation={true}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

            var lastID = origin.item.id;
            var list = document.getElementById(lastID).getElementsByClassName("text");
            for (const element of list) {
              element.classList.remove('fadeInUp');
              element.classList.add('fadeOutUp');
            }

          }}
          afterLoad={(origin, destination, direction) => {

            console.log(destination.item.id);
            var currentID = destination.item.id;

            if (direction != null || currentID === 'titan-in-the-outfield') {
              var list = document.getElementById(currentID).getElementsByClassName("text");
              // console.log(list)
              // list[0].classList.remove('fadeOutUp');
              // list[0].classList.add('animated','fadeInUp');

              for (const element of list) {
                element.classList.remove('fadeOutUp');
                element.classList.add('animated','fadeInUp');
              }
            }

          }}
          responsiveWidth={'769'}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {list}
                <section className='section' id="class-notes" style={{backgroundImage: `url(${ClassNotesHero})`}}>
                  <div className='text left'>
                    <h2>Alumni Class Notes</h2>
                    <span className="subtitle">Class Notes is the place to catch up. Let fellow alumni know about career moves, awards and honors, publications and performances, births and marriages, and other life events.</span>
                    <a href="https://alumni.fullerton.edu/class-notes/" target="_blank" className="story-button">Visit Class Notes <Icon name="arrow-right" alt="" class="xs" /></a>
                  </div>
                  <div className='tint left' />
                </section>
                <section className='section' id="end-cta">

                  <div className='text left'>
                    <h2><img src={CSUFNews} alt="CSUF News" style={{width: '250px'}}  /></h2>

                    <ul>
                      <li key="news-0">
                        <a href="https://news.fullerton.edu/2023/02/fullerton-forward-2024-2029-cal-state-fullerton-prepares-for-new-five-year-strategic-plan/" target="_blank" rel="nofollow noreferrer">
                          <img src="https://news.fullerton.edu/app/uploads/2023/02/1-22-18_first_day_classes_mg_013-800x500.jpg" alt="" />
                          <span>Fullerton Forward 2024-2029: Cal State Fullerton Prepares for New Five-Year Strategic Plan</span>
                        </a>
                      </li>
                      <li key="news-1">
                        <a href="https://news.fullerton.edu/2023/01/visual-arts-modernization-project-reimagines-the-future-of-art-education/" target="_blank" rel="nofollow noreferrer">
                          <img src="https://news.fullerton.edu/app/uploads/2023/01/Screen-Shot-2023-01-30-at-3.47.08-PM-800x500.png" alt="" />
                          <span>Visual Arts Modernization Project Reimagines the Future of Art Education</span>
                        </a>
                      </li>
                      <li key="news-2">
                        <a href="https://news.fullerton.edu/2023/02/u-s-news-world-report-ranks-csuf-among-nations-top-online-graduate-programs/" target="_blank" rel="nofollow noreferrer">
                          <img src="https://news.fullerton.edu/app/uploads/2023/02/3T8A0028-800x500.jpg" alt="" />
                          <span>U.S. News & World Report Ranks CSUF Among Nation’s Top Online Graduate Programs</span>
                        </a>
                      </li>
                      <li key="news-3">
                        <a href="https://news.fullerton.edu/2023/03/amir-dabirian-appointed-provost-and-vice-president-for-academic-affairs/" target="_blank" rel="nofollow noreferrer">
                          <img src="https://news.fullerton.edu/app/uploads/2023/03/Amir-Dabirian-800x500.jpg" alt="" />
                          <span>Amir Dabirian Appointed Provost and Vice President for Academic Affairs</span>
                        </a>
                      </li>
                      <li key="news-4">
                        <a href="https://news.fullerton.edu/2023/04/arnold-holland-appointed-dean-of-college-of-the-arts/" target="_blank" rel="nofollow noreferrer">
                          <img src="https://news.fullerton.edu/app/uploads/2021/08/Arnold-Holland-800x500.jpg" alt="" />
                          <span>Arnold Holland Appointed Dean of College of the Arts</span>
                        </a>
                      </li>
                    </ul>

                    <p>Stay up to date on all the latest campus news by visiting the <a href="http://news.fullerton.edu/" target="_blank" rel="noreferrer">CSUF News website</a>. Submit story ideas to <a href="https://news.fullerton.edu/spotlight/">Titan Spotlight</a>.</p>
                  </div>
                </section>
                <section className='section fp-auto-height' id="footer"><Footer /></section>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size={30} frontColor="#00274C" backColor="#FF7900" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

        
      </Layout>
    );
  }
}